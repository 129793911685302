import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout";

const Dean = () => {
  return (
    <Layout>
      <Head title="Dean Cobin" />
      <h3>Dean Cobin</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        New York
      </h4>
      <p>
        <OutboundLink href="https://www.deancobin.com/">Website</OutboundLink>
      </p>
      {/* <p>
        <OutboundLink href="https://ello.co/deancobin">Ello</OutboundLink>
      </p> */}
      <p>
        Others in the world of photography have recognized Dean's extraordinary
        talent for capturing a bit of "eternity now" by publishing and bestowing
        numerous awards for his work. The roster includes a virtual list of the
        most respected names in photography like Outdoor Photographer,
        Adirondack Life, National Geographic's Your Shot, Landscape Photography
        Magazine, and the UK's Digital Photographer.
      </p>
      <img
        src="https://www.deancobin.com/wp-content/uploads/2018/08/19A0060-Edit.jpg"
        alt="Dean Cobin Photography"
      />
      <img
        src="https://www.deancobin.com/wp-content/uploads/2022/02/28A0439-2-Edit.jpg"
        alt="Dean Cobin Photography"
      />
      <img
        src="https://www.deancobin.com/wp-content/uploads/2015/12/19A4036-4.jpg"
        alt="Dean Cobin Photography"
      />
    </Layout>
  );
};

export default Dean;
